.margin-gall {
  margin: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}
// .pers-height {
//   height: 713.99px;
//   object-fit: cover;
// }
