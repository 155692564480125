.about-pers {
  margin-left: auto;
  margin-top: 2rem;
}

.map-one {
  height: 50vh;
  width: 100%;
  margin-top: 1rem;
}

.mt-personal {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.pers-para {
  text-align: justify;
  max-width: 80%;
}
