.main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.position-absolute {
  top: 13%;
  left: 60%;
}

:root {
  --purple: hsl(240, 80%, 89%);
  --pink: hsl(0, 59%, 94%);
  --light-bg: hsl(204, 37%, 92%);
  --light-gray-bg: hsl(0, 0%, 94%);
  --white: hsl(0, 0%, 100%);
  --dark: hsl(0, 0%, 7%);
  --text-gray: hsl(0, 0%, 30%);
}

body {
  background: #f8f9fa !important;
  font-family: 'Space Grotesk', sans-serif;
  color: var(--dark);
}

h3 {
  font-size: 1.5em;
  font-weight: 700;
}

p {
  font-size: 1em;
  line-height: 1.7;
  font-weight: 300;
  color: var(--text-gray);
}

.button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
a {
  color: black;
}

.button:after {
  content: '»';
  content: '»';
  /* display: flex; */
  /* position: absolute; */
  opacity: 0;
  font-size: 20px;
  margin-left: 10px;
  /* bottom: 0.1px; */
  /* right: -20px; */
  /* padding-bottom: 19px; */
  left: -15px;
  /* margin-bottom: 5px; */
  transition: 0.5s;
}

.button:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.button:hover:after {
  opacity: 1;
  right: 10px;
}
.description {
  white-space: wrap;
}

.wrap {
  display: flex;
  align-items: stretch;
  width: 80%;
  gap: 24px;
  margin-left: 10%;
  padding: 24px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  padding: 24px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.box-top {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  padding-bottom: 12px;
}

.box-image {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: var(--bs-border-radius) !important;
  // object-position: 50% 20%;
}

.title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-title {
  border-left: 3px solid var(--purple);
  padding-left: 12px;
}

.user-follow-info {
  color: hsl(0, 0%, 60%);
}

.custom-a {
  text-decoration: none !important;
  color: black;
  --bs-link-hover-color: darkgrey;
}

.king-bed {
  margin-left: 3px;
}
/* RESPONSIVE QUERIES */

@media (min-width: 320px) {
  .title-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .user-follow-info {
    margin-top: 6px;
  }
}

@media (min-width: 460px) {
  .title-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
  .user-follow-info {
    margin-top: 6px;
  }
}

@media (min-width: 640px) {
  .box {
    flex-basis: calc(50% - 12px);
  }
  .title-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .user-follow-info {
    margin-top: 6px;
  }
}

@media (min-width: 840px) {
  .title-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
  .user-follow-info {
    margin-top: 6px;
  }
}

@media (min-width: 1024px) {
  .box {
    flex-basis: calc(33.3% - 16px);
  }
  .title-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .user-follow-info {
    margin-top: 6px;
    display: flex;
  }
}
