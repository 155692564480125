body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
/* For Chrome, Edge, and Safari */
html::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
html {
  scrollbar-width: none;
  overflow: scroll;
}

/* For Internet Explorer (if needed) */
html {
  -ms-overflow-style: none;
}
