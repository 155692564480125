.foot_last {
  margin-top: 3rem;
  background-color: rgb(213, 213, 213);
}

.left_margin {
  margin-left: auto;
}

.margin_top {
  margin-top: 2rem;
}
.pers-styl {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

.img-logo {
  width: 100%;
}

.margin-custom {
  margin-right: 8rem;
}

.social-icons {
  display: flex;
  justify-content: space-between;
}

.margin-pers {
  margin-left: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}
