/* Hide the scroll bar */

::-webkit-scrollbar {
  width: 0.5em;
  background-color: #f5f5f5;
}

/* Hide the scroll thumb */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  z-index: 2;
}

/* Enable scrolling */

.hearth-img {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.hearth-img > img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: -1;
  opacity: 0.5;
}

.hearth-title {
  position: absolute;
  top: 50%;
  left: 29%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #333333;
  font-size: 6rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 10);
  text-align: center;
}
.booking-engine-button {
  display: none;
  background-color: #929292;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  padding: 12px 24px;
  margin: 8px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 8rem;
}

.booking-engine-button:hover {
  background-color: #9e9e9e;
}

/* Media query for smaller screens */
@media screen and (max-width: 767px) {
  .booking-engine-iframe {
    display: none;
  }

  .booking-engine-button {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .hearth-img {
    height: 50vh;
  }
  .hearth-title {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 768px) {
  .hearth-img {
    height: 40vh;
  }
  .hearth-title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 480px) {
  .hearth-img {
    height: 30vh;
  }
  .hearth-title {
    font-size: 2rem;
  }
}
