body {
  overflow: hidden;
}

.navbar-nav {
  margin-left: auto;
  padding-right: 1rem;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  z-index: 100;
}
// Adjust the logo and Book Now button
.navbar {
  // Add logo padding
  .navbar-brand {
    padding-left: 2rem; // Adjust this value to move the logo to the right
  }

  // Add Book Now button padding
  .btn-outline-secondary {
    margin-right: 2rem;
    height: 35px;
    width: 8%;
    font-size: 1.1rem; // Adjust this value to move the button to the left
  }
}

// Add padding between Home, Room and Suits, and Services
.nav-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 991px) {
  .no-scroll {
    overflow: hidden;
  }

  .navbar {
    .navbar-brand {
      padding-left: 1.5rem;
    }
    .btn-outline-secondary {
      margin-right: 1.5rem;
      font-size: 1rem;
      display: none;
    }
  }
  .nav-item {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 2rem;
  }
  .custom-toggler {
    margin-right: 20px;
  }
  .navbar-collapse {
    transition: height 0.35s ease-in-out;
    height: 100vh;
    overflow: hidden;
  }

  .navbar-collapse:not(.show) {
    height: 0 !important;
  }
  .navbar-nav {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    .navbar-brand {
      padding-left: 1rem;
    }
    .btn-outline-secondary {
      margin-right: 1rem;
      font-size: 0.9rem;
    }
  }
  .nav-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .navbar {
    .navbar-brand {
      padding-left: 0.5rem;
    }
    .btn-outline-secondary {
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }
  }
  .nav-item {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}
@media only screen and (max-width: 412px) {
  .navbar {
    .navbar-brand {
      padding-left: 0.5rem;
    }
    .btn-outline-secondary {
      margin-right: 0.4rem;
      font-size: 0.7rem;
    }
  }
  .nav-item {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .custom-toggler {
    margin-right: 20px;
  }
  .box-image {
    height: 200px !important;
  }
  .user-follow-info {
    display: none;
  }
  .wrap {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 390px) {
  .navbar {
    .navbar-brand {
      padding-left: 0.4rem;
    }
    .btn-outline-secondary {
      margin-right: 0.4rem;
      font-size: 0.7rem;
    }
  }
  .nav-item {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .custom-toggler {
    margin-right: 20px;
  }
  .box-image {
    height: 200px !important;
  }
  .user-follow-info {
    display: none;
  }
  .wrap {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
