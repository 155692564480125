.container {
  margin-top: 30px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  line-height: 1.5;
}

.mar-pers {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.img-pers {
  border-radius: var(--bs-border-radius) !important;
}
